var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
      },
      [
        _c("div", { staticClass: "kt-portlet kt-portlet--height-fluid" }, [
          _c("div", { staticClass: "kt-portlet__head" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "kt-portlet__head-toolbar" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { to: { name: "CreateBugReport" } },
                  },
                  [_vm._v(" Create Issue ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "kt-portlet__body" }, [
            _c("div", { staticClass: "tab-content" }, [
              _c("div", { staticClass: "tab-pane active" }, [
                _vm.bugReports.length > 0
                  ? _c(
                      "div",
                      { staticClass: "kt-widget5" },
                      _vm._l(_vm.bugReports, function (issue) {
                        return _c(
                          "div",
                          {
                            key: `bugReportId_${issue.bugReportId}`,
                            staticClass: "kt-widget5__item",
                          },
                          [
                            _c("div", { staticClass: "kt-widget5__content" }, [
                              _c("div", { staticClass: "kt-widget5__pic" }, [
                                issue.videoUrl
                                  ? _c("video", {
                                      staticClass: "thumb",
                                      attrs: { src: issue.videoUrl },
                                    })
                                  : _vm._e(),
                              ]),
                              _c(
                                "div",
                                { staticClass: "kt-widget5__section" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "kt-widget5__title",
                                      attrs: { href: "#" },
                                    },
                                    [_vm._v(" " + _vm._s(issue.bugTitle) + " ")]
                                  ),
                                  _c("p", { staticClass: "kt-widget5__desc" }, [
                                    _vm._v(
                                      " " + _vm._s(issue.bugDescription) + " "
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "kt-widget5__info" },
                                    [
                                      _c("span", [_vm._v("Author:")]),
                                      _c(
                                        "span",
                                        { staticClass: "kt-font-info" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(issue.author) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "kt-widget5__content" }, [
                              _c("div", { staticClass: "kt-widget5__stats" }, [
                                _c(
                                  "span",
                                  { staticClass: "kt-widget5__number" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(issue.githubState) + " "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._m(1, true),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _c("div", [_vm._v(" There are no open issues. ")]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head-label" }, [
      _c("h3", { staticClass: "kt-portlet__head-title" }, [
        _vm._v(" Open Issues "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-widget5__stats d-none" }, [
      _c("span", { staticClass: "kt-widget5__number" }, [_vm._v("1046")]),
      _c("span", { staticClass: "kt-widget5__votes" }, [_vm._v("votes")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }