<template>
<div>
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--height-fluid">
            <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        Open Issues
                    </h3>
                </div>
                <div class="kt-portlet__head-toolbar">
                    <router-link
                        :to="{name: 'CreateBugReport'}"
                        class="btn btn-primary"
                    >
                        Create Issue
                    </router-link>
                </div>
            </div>
            <div class="kt-portlet__body">
                <div class="tab-content">
                    <div class="tab-pane active">
                        <div
                            v-if="bugReports.length > 0"
                            class="kt-widget5"
                        >
                            <div
                                v-for="issue in bugReports"
                                :key="`bugReportId_${issue.bugReportId}`"
                                class="kt-widget5__item"
                            >
                                <div class="kt-widget5__content">
                                    <div class="kt-widget5__pic">
                                        <video
                                            v-if="issue.videoUrl"
                                            class="thumb"
                                            :src="issue.videoUrl"
                                        />
                                    </div>
                                    <div class="kt-widget5__section">
                                        <a
                                            href="#"
                                            class="kt-widget5__title"
                                        >
                                            {{ issue.bugTitle }}
                                        </a>
                                        <p class="kt-widget5__desc">
                                            {{ issue.bugDescription }}
                                        </p>
                                        <div class="kt-widget5__info">
                                            <span>Author:</span>
                                            <span class="kt-font-info"> {{ issue.author }} </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="kt-widget5__content">
                                    <div class="kt-widget5__stats">
                                        <span class="kt-widget5__number">
                                            {{ issue.githubState }}
                                        </span>
                                    </div>
                                    <div class="kt-widget5__stats d-none">
                                        <span class="kt-widget5__number">1046</span>
                                        <span class="kt-widget5__votes">votes</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            There are no open issues.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import userMixins from '../store/mixins/userMixins';
import * as network from '../network';

export default {
    name: 'ListBugReports',
    mixins: [userMixins],
    data() {
        return {
            loading: false,
            bugReports: [],
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        populate() {
            const { showError } = this;
            this.loading = true;
            const v = this;
            const { schoolId, schoolTermId } = this.$store.state.user.school;
            const params = { url: { schoolId, schoolTermId } };
            network.schools.listBugReportsForSchool(params, (err, res) => {
                v.loading = false;
                if (err) return showError(err);
                v.bugReports = res.bugReports;
            });
        },
    },
};
</script>

<style>
.button-width {
    width: 3.75rem;
}

video.thumb {
    width: 160px;
    background: #000;
}
</style>
